<template>
  <div class="bg-white py-4 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl sm:text-center">
        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Welcome To BasicoCrm</p>
        <h2 class="text-base font-semibold leading-7 text-indigo-600">Everything you need in a CRM</h2>
        <p class="mt-6 text-lg leading-8 text-gray-600">A complete dashboard,to have everything summarized and under your control</p>
      </div>
    </div>
    <div class="relative overflow-hidden pt-16">
      <div class="mx-auto max-w-7xl px-6 lg:px-8" id="imagen">
        <img  src="@/assets/images/dashboard.jpg" class="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10" width="2432" height="1442">
        <div class="relative" aria-hidden="true">
          <div class="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="md:ml-20 bg-white py-4 md:py-24 md:mt-20">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <div class="lg:ml-auto lg:pl-4 lg:pt-4">
          <div class="lg:max-w-lg">
            <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Sales Funnel and Revenue Chart for your Deals</p>
            <p class="mt-6 text-lg leading-8 text-gray-600">Control the profits and status of your deals, with two essential tools</p>
          </div>
        </div>
        <div class="flex items-start justify-end lg:order-first">
          <img src="@/assets/images/estadistics.png" alt="Product screenshot" class="w-[48rem]  max-w-full rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]" width="2432" height="1442">
        </div>
      </div>
      <div class=" mt-8 ml grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <div class="flex items-start justify-end  lg:order-first">
          <img src="@/assets/images/clients.png" alt="Product screenshot" class="w-[48rem]  max-w-full rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]" width="2432" height="1442">
        </div>
        <div class="lg:ml-auto lg:pl-4 lg:pt-4">
          <div class="lg:max-w-lg">
            <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Manage Users,Tasks,Contacts...</p>
            <p class="mt-6 text-lg leading-8 text-gray-600">You can create,update & delete,filter by order,search, see details,and more </p>

          </div>
        </div>

      </div>
    </div>
  </div>




</template>
<script setup>
</script>
<style scoped>


</style>