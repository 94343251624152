<template>
  <div class="mx-auto text-center max-w-2xl px-4">
    <register-form></register-form>
  </div>
</template>
<script>

import RegisterForm from "@/views/Register/components/RegisterForm.vue";

export default {

  components: {
    RegisterForm
  }

}
</script>